<template>
  <div class="ui segments">
    <div class="ui segment">
      <div class="moon-multiselect">
        <multiselect v-model="multiselect.value" :options="multiselect.items" label="text" track-by="value" multiple taggable @tag="addItem"></multiselect>
      </div>

      {{ multiselect.value }}
    </div>
    <div class="ui segment">
      <m-date-button :year="year" :month="month" :day="day" @select="selectMonth"></m-date-button>
      <div class="ui divider"></div>
      <m-date-button :year="year" :month="month" type="month" @select="selectMonth"></m-date-button>
      <div class="ui divider"></div>
      <div class="ui calendar" id="button_calendar_month" ref="month">
        <div class="ui button">Select Month</div>
      </div>
      {{ selectedMonth }}
      <div class="ui divider"></div>
      <div class="ui calendar" id="button_calendar_date" ref="date">
        <div class="ui button">Select Date</div>
      </div>
      {{ selectedDate }}
    </div>

    <div class="ui segment">
      <m-btn @click="showDialog1 = true">Open Dialog</m-btn>
      <m-dialog v-model="showDialog1" header-text="제목" fullscreen>
        <div class="ui form">
          <m-field-text label="Text" :valid="true"></m-field-text>
          <m-field-dropdown
            label="Gender"
            placeholder="Gender"
            :items="[
              { value: 1, name: 'Male' },
              { value: 0, name: 'Female' },
            ]"
            :valid="true"
          ></m-field-dropdown>
        </div>
        <m-btn @click="showDialog2 = true">Open Dialog 2</m-btn>
        <template #actions>
          <m-btn @click="showDialog1 = false">Close</m-btn>
        </template>
      </m-dialog>
      <m-dialog v-model="showDialog2" header-text="제목">
        <div>Dialog 2</div>
        <template #actions>
          <m-btn @click="showDialog2 = false">Close</m-btn>
        </template>
      </m-dialog>
    </div>
    <div class="ui segment">
      <div class="ui form">
        <m-field-dropdown icon label="Column Types" placeholder="Column Types" :items="types" :valid="true" v-model="type"></m-field-dropdown>
        <div>{{ type }}</div>
      </div>
    </div>
    <div class="ui segment">
      <input type="checkbox" v-model="draggable" /> Draggable: {{ draggable }}
      <draggable ghost-class="ghost" :disabled="!draggable" class="box-container" v-model="myArray" group="people" @start="drag = true" @end="drag = false">
        <transition-group type="transition">
          <div class="ui box" v-for="element in myArray" :key="element.id">
            {{ element.name }}
          </div>
        </transition-group>
      </draggable>
    </div>
    <div class="ui segment">
      <m-btn @click="showLoader">Loader</m-btn>
      <m-btn @click="showProgress">Progress</m-btn>
      <m-btn @click="showToast">Toast Success</m-btn>
      <m-btn @click="showToastErr">Toast Error</m-btn>
      <m-btn @click="showNotice">Notice Success</m-btn>
      <m-btn @click="showNoticeErr">Notice Error</m-btn>
    </div>
    <div class="ui segment">
      <m-btn color="primary">Primary</m-btn>
      <m-btn color="secondary">Secondary</m-btn>
      <m-btn color="blue" loading>Button</m-btn>
      <m-btn disabled>Button</m-btn>
      <m-btn color="yellow">Button</m-btn>
      <m-btn color="orange">Button</m-btn>
    </div>
    <div class="ui segment">
      <button class="ui red button">Red</button>
      <button class="ui orange button">Orange</button>
      <button class="ui yellow button">Yellow</button>
      <button class="ui olive button">Olive</button>
      <button class="ui green button">Green</button>
      <button class="ui teal button">Teal</button>
      <button class="ui blue button">Blue</button>
      <button class="ui violet button">Violet</button>
      <button class="ui purple button">Purple</button>
      <button class="ui pink button">Pink</button>
      <button class="ui brown button">Brown</button>
      <button class="ui grey button">Grey</button>
      <button class="ui black button">Black</button>
    </div>
    <div class="ui segment">
      <m-select
        :items="[
          { name: 'xxx', value: 'xxx' },
          { name: 'yyy', value: 'yyy' },
        ]"
      ></m-select>
    </div>
  </div>
</template>

<script>
import MBtn from "@/components/common/MBtn"
import MSelect from "@/components/common/MSelect"
import MDialog from "@/components/common/MDialog"
import { toast, notice } from "@/utils/SemanticUtil"
import draggable from "vuedraggable"
import MFieldDropdown from "@/components/common/MFieldDropdown"
import MFieldText from "@/components/common/MFieldText"
import { types } from "@/define/ColumnTypes"
import moment from "moment"
import MDateButton from "@/components/common/MDateButton"
export default {
  name: "SamplePage",
  components: {
    MDateButton,
    MFieldText,
    MFieldDropdown,
    MDialog,
    MSelect,
    MBtn,
    draggable,
  },
  data() {
    return {
      multiselect: {
        value: null,
        items: [
          { text: "Vue.js", value: "vu" },
          { text: "Javascript", value: "js" },
          { text: "Open Source", value: "os" },
        ],
      },
      year: 2021,
      month: 7,
      day: 8,
      selectedMonth: null,
      selectedDate: null,
      gender: 0,
      type: null,
      showDialog1: false,
      showDialog2: false,
      drag: false,
      draggable: true,
      myArray: [
        { id: 1, name: "card1" },
        { id: 2, name: "card2" },
        { id: 3, name: "card3" },
      ],
    }
  },
  computed: {
    types() {
      return types
    },
  },
  methods: {
    addItem(newItem) {
      const item = {
        text: newItem,
        value: newItem.substring(0, 2) + Math.floor(Math.random() * 10000000),
      }

      this.multiselect.items.push(item)
      if (this.multiselect.value === null) {
        this.multiselect.value = []
      }
      this.multiselect.value.push(item)
    },
    selectMonth(date) {
      this.year = moment(date).format("Y")
      this.month = moment(date).format("M")
    },
    showLoader() {
      this.$store.commit("page/onLoader")
      setTimeout(() => {
        this.$store.commit("page/offLoader")
      }, 3000)
    },
    showProgress() {
      this.$store.commit("page/onProgress")
      setTimeout(() => {
        this.$store.commit("page/offProgress")
      }, 3000)
    },
    showToast() {
      toast("안녕하세요 : " + new Date().toLocaleString())
    },
    showToastErr() {
      toast("오류 났어요 : " + new Date().toLocaleString(), "error")
    },

    showNotice() {
      notice("안녕하세요 : " + new Date().toLocaleString())
    },
    showNoticeErr() {
      notice("오류 났어요 : " + new Date().toLocaleString(), "error")
    },
  },
  created() {
    this.$store.commit("page/changeTitle", "Simple Page")
  },
  mounted() {
    // $("#button_calendar").calendar({ type: "month" })
    // const self = this
    $(this.$refs.month).calendar({
      today: true,
      type: "month",
      onChange: (date) => {
        this.selectedMonth = date
      },
    })
    $(this.$refs.date).calendar({
      today: true,
      type: "date",
      onChange: (date) => {
        this.selectedDate = date
      },
    })
  },
}
</script>

<style scoped>
.box {
  cursor: move;
  background: #ffffff;
  margin-bottom: 10px;
  height: 50px;
  display: block;
  border: solid 1px #0c85d0;
}

.ghost {
  background: #bcdef3;
}

.box-container {
}
</style>
