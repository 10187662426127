<template>
  <div class="ui selection dropdown" ref="select">
    <input type="hidden" name="select" v-model="value" />
    <div class="text"></div>
    <i class="dropdown icon"></i>
  </div>
</template>

<script>
import lodash from "lodash"
export default {
  name: "MSelect",
  props: {
    initValue: null,
    items: Array,
  },
  data() {
    return {
      value: null,
    }
  },
  computed: {
    selectedValues() {
      return lodash.map(this.items, (item) => {
        if (item.value == this.initValue) {
          return { ...item, selected: true }
        } else {
          return item
        }
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.value = this.initValue
      $(this.$refs.select).dropdown({
        values: this.selectedValues,
        onChange: (value, text, $choice) => {
          console.debug(value, text)
          console.debug($choice)
          this.$emit("onChange", value)
        },
      })
    })
  },
}
</script>

<style scoped></style>
